import React, { useCallback, useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { useMe } from '@braincube/header';
import tracker from '@braincube/tracker';

import { redirectToSso } from 'components/Routes/Sso';

import Apps from './Apps';
import AppsPlatform from './AppsPlatform';
import Offers from './Offers';
import Versions from './Versions';

function redirectToApps() {
    return <Redirect to="/apps" />;
}

function renderAppsPlatform() {
    return <AppsPlatform />;
}

function renderOffers() {
    return <Offers />;
}

function renderVersions() {
    return <Versions />;
}

function AppsManagerRoutes() {
    const { accessList, user } = useMe();

    const location = useLocation();
    tracker.matomo.usePageView(location);

    useEffect(() => {
        if (!user.rootAdmin) {
            redirectToSso();
        }
    }, [user.rootAdmin]);

    const renderApps = useCallback(() => {
        return <Apps accessList={accessList.filter((access) => ['braincube', 'iot'].includes(access.product.type))} />;
    }, [accessList]);

    return (
        <>
            <Route path="/apps" render={renderApps} exact />
            <Route path="/apps-platform" render={renderAppsPlatform} exact />
            <Route path="/offers" render={renderOffers} exact />
            <Route path="/versions" render={renderVersions} exact />

            <Route path="/" render={redirectToApps} exact />
        </>
    );
}

export default AppsManagerRoutes;
