import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import './index.css';

import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { SnackbarProvider } from 'notistack';
import merge from 'lodash.merge';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';

import { LabelsMapping, SplashScreen } from '@braincube/ui-lab';
import { AdminSsoFlat } from '@braincube/svg';
import { buildTheme } from '@braincube/theme';
import { I18nProvider, getLocaleKey } from '@braincube/i18n';
import { HeaderContextProvider, headerLabels, usePreferences, useSites, useMe, useIsReady } from '@braincube/header';
import Logger from '@braincube/logger';
import { i18nLabels } from '@braincube/ui';
import { LabelsMapping as DataGridLabelsMapping } from '@braincube/data-grid';
import tracker from '@braincube/tracker';

import localesMap from './services/lang';
import { AppContext, AppContextProvider, setMeFromUniverse } from './app-context';
import App from './components/App';
import pkg from '../package.json';

LicenseInfo.setLicenseKey(import.meta.env.VITE_REACT_APP_XGRID_LICENCE_KEY);

function Test() {
    const isReady = useIsReady();
    const { locale } = usePreferences();
    const me = useMe();
    const { dispatch } = useContext(AppContext);

    const { selectedSite } = useSites();

    useEffect(() => {
        if (selectedSite && me) {
            tracker.configure({
                appName: 'sso-admin',
                apiName: selectedSite.siteName,
                appVersion: import.meta.env.VITE_APP_VERSION,
                user: me.user,
                selectedSiteName: selectedSite.siteName,
                matomoSiteId: '22',
            });
        }
    }, [me, selectedSite]);

    useEffect(() => {
        if (isReady && me.groupsList) {
            setMeFromUniverse(dispatch, me.groupsList);
        }
    }, [isReady, me, dispatch]);

    const localeKey = getLocaleKey(locale);

    return (
        <I18nProvider
            labels={merge(headerLabels, localesMap, i18nLabels, LabelsMapping, DataGridLabelsMapping).getLabels(
                localeKey
            )}
            locale={localeKey}
        >
            <SplashScreen
                AppLogo={AdminSsoFlat}
                appName={window.location.pathname.includes('braincube-users') ? 'Users' : 'SSO Admin'}
                appVersion={import.meta.env.VITE_APP_VERSION}
                isContentLoaded={isReady}
            >
                <App />
            </SplashScreen>
        </I18nProvider>
    );
}

const head = document.getElementsByTagName('head')[0];
const script = document.createElement('script');

script.async = false;
script.type = 'text/javascript';

if (import.meta.env.DEV) {
    script.src = `https://cdn.test.mybraincube.com/configuration.js?timestamp=${Date.now()}`;
} else {
    script.src = `https://${window.location.hostname.replace('admin', 'cdn')}/configuration.js?timestamp=${Date.now()}`;
}

const anchorOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

const theme = buildTheme();

function Init() {
    useEffect(() => {
        Logger.configure({
            sentry: import.meta.env.VITE_LOGGER_SENTRY === 'true',
            appName: 'sso-admin',
            version: pkg.version,
            sentryProjectId: 21,
            sentryToken: '24583a93cbfd44549d0ffb3ce43c13a2',
        });
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <SnackbarProvider
                    maxSnack={1}
                    anchorOrigin={anchorOrigin}
                    autoHideDuration={import.meta.env.VITE_SNACKBAR_DELAY}
                >
                    <CssBaseline />
                    <AppContextProvider>
                        <Router>
                            <HeaderContextProvider>
                                <Test />
                            </HeaderContextProvider>
                        </Router>
                    </AppContextProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

script.onload = function render() {
    ReactDOM.render(<Init />, document.querySelector('#root'));
};

head.appendChild(script);
