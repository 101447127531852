import React from 'react';

import { ErrorPage } from '@braincube/ui-lab';
import { useI18n } from '@braincube/i18n';

function ErrorMessage({ messageKey }) {
    const i18n = useI18n();
    return <ErrorPage title={i18n.tc('ssoAdmin.licence.errors.noDistributor.title')} details={i18n.tc(messageKey)} />;
}

// eslint-disable-next-line import/prefer-default-export
export function NoDistributor({ canAddLicence }) {
    if (canAddLicence) {
        return <ErrorMessage messageKey="ssoAdmin.licence.errors.noDistributor.doItYourself" />;
    }

    return <ErrorMessage messageKey="ssoAdmin.licence.errors.noDistributor.askAdmin" />;
}
